var CommonMixin = {
  methods: {
    datetimeToTimestamp(datetime) {
      return this.$moment(datetime).format("X");
    },
    notificationError(msgErr) {
      this.$notification.error({
        message: msgErr || "Something went wrong, please try again"
      });
    },
    filterIsInactiveOption(option) {
      return !option.isInactive;
    }
  }
};
export { CommonMixin as C };
